import React, { useState } from 'react';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import User from '../people-partners/User';
import Loader from '../../components/Loader';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import SectionHeader from '../../components_profiles/SectionHeader';
import { LinkButton, OutlineButton } from '../../components/Button';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

export default function Partners(props) {
  const currentUser = useUser();

  const userBookmarks = useConnect('bookmark.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          'user.tags.name': 'Educator'
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (userBookmarks.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <div className="space-y-4">
      <SectionHeader
        title="Educators"
        subtitle={`${userBookmarks.data.length} educator${userBookmarks.data.length === 1 ? '' : 's'} bookmarked`}
      />
      {userBookmarks.data.length === 0 ? (
        <EmptyState
          icon={AcademicCapIcon}
          title="Looks like you don’t have any educators bookmarked yet."
          subtitle="After bookmarking educators, you will see them here."
        >
          <EmptyStateActions>
            <LinkButton
              button={OutlineButton}
              to="/educators-populate"
              label="Explore educators"
            />
          </EmptyStateActions>
        </EmptyState>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-10">
          {userBookmarks.data.map(function(userBookmark, index) {
            return (
              <UseQueryConnect key={index} _key="user.byId" params={{ id: userBookmark.data.userId }}>
                {function(user) {
                  if (user.state === PayloadStates.FETCHING) {
                    return (
                      <Loader key={index} />
                    );
                  }

                  return (
                    <User user={user} />
                  );
                }}
              </UseQueryConnect>
            );
          })}
        </div>
      )}
    </div>
  );
};
