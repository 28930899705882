import React from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import User from '../people-partners/User';
import mapResponse from '../../utils/mapResponse';

export default function EventUser(props) {
  const { user, event } = props;

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  const invitation = _.find(invitations.data, function(invitation) {
    return invitation.data.userId === user.id;
  });

  const selected = !!invitation;

  return (
    <User
      user={user}
      event={event}
      selected={selected}
      footer={invitation ? mapResponse(invitation.data.response) : undefined}
    />
  );
}
