import React from 'react';
import useUserProfileMissingFields from '../hooks/useUserProfileMissingFields';
import Banner from './Banner';
import { DialogManager } from '../dialogs_new/DialogManager';
import useDialogLauncher from '../dialogs-routable/_hooks/useDialogLauncher';
import useIntents from '../hooks/useIntents';
import useEducatorProfileMissingFields from '../hooks/useEducatorProfileMissingFields';
import usePartnerProfileMissingFields from '../hooks/usePartnerProfileMissingFields';

export default function Layout(props) {
  const { isEducator, isPartner } = useIntents();
  const missingFields = useUserProfileMissingFields();
  const educatorMissingFields = useEducatorProfileMissingFields();
  const partnerMissingFields = usePartnerProfileMissingFields();

  const launch = useDialogLauncher();

  if (
    !missingFields ||
    !educatorMissingFields ||
    !partnerMissingFields
  ) {
    return null;
  }

  const fieldCount = missingFields.profile.length + missingFields.interests.length;
  const educatorFieldCount = educatorMissingFields.profile.length + educatorMissingFields.interests.length;
  const partnerFieldCount = partnerMissingFields.profile.length + partnerMissingFields.interests.length;

  function onCompleteProfile() {
    launch(DialogManager.ProfileMissing.Config());
  }

  const banners = [];

  if (isEducator && educatorFieldCount > 0) {
    banners.push(
      <Banner key="educator-banner">
        <div>
          You need to <span className="mx-1 text-white underline cursor-pointer" onClick={onCompleteProfile}>complete your profile</span> before
          you can create a project or event!
        </div>
      </Banner>
    );
  }

  if (isPartner && partnerFieldCount > 0) {
    banners.push(
      <Banner key="partner-banner">
        <div>
          You need to <span className="mx-1 text-white underline cursor-pointer" onClick={onCompleteProfile}>complete your profile</span> before
          educators will be able to invite you to projects and events!
        </div>
      </Banner>
    );
  }

  if (banners.length > 0) {
    return banners;
  }

  return null;
};
