import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import Form from './Form';
import { Helmet } from 'react-helmet-async';
import useRouter from '../../../hooks/useRouter';
import useSetupNavigation from '../useSetupNavigation';

export default function Layout(props) {
  const { history } = useRouter();

  const user = useUser();

  const navigation = useSetupNavigation(Urls.AVATAR);

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Avatar</title>
      </Helmet>
      <Form
        user={user}
        navigation={navigation}
        onPrevious={() => history.push(Urls.DEMOGRAPHICS)}
        onNext={() => history.push(Urls.FINISH)}
        onSkip={() => history.push(Urls.FINISH)}
      />
    </ParentLayout>
  );
};
