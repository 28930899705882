import React, { Fragment } from 'react';
import Section from '../../my-network/Section';
import OptionCard from '../../my-network/OptionCard';
import canvaCalendar from '../../../../assets/images/canva/canva-calendar.png';
import canvaHands from '../../../../assets/images/canva/canva-hands.png';

export default function GetInvolved(props) {
  return (
    <Section
      title="Get involved"
      // subtitle={(
      //   <>
      //     Discover educators who are leading exciting projects and events you might
      //     contribute to. Find community partners who have skills, knowledge, and experiences
      //     to help bring your projects to life.
      //   </>
      // )}
      subtitle={(
        <>
          Explore opportunities to get involved.
        </>
      )}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3x gap-4">
        <OptionCard
          centeredImage={(
            <img className="h-40 w-40" src={canvaCalendar} alt="Logo"/>
          )}
          // centeredImageBackgroundColor="bg-cs-gray-100"
          centeredImageBackgroundColor="bg-cs-blue-500"
          // centeredImageBackgroundColor="bg-cs-orange-50"
          title="Explore current activities that match your interests"
          subtitle="Discover opportunities to get involved!"
          linkText="View"
          to="/opportunities"
        />
        <OptionCard
          centeredImage={(
            <img className="h-40 w-40" src={canvaHands} alt="Logo"/>
          )}
          // centeredImageBackgroundColor="bg-cs-gray-100"
          centeredImageBackgroundColor="bg-cs-blue-500"
          title="Discover people in your community"
          subtitle="Explore educators and find community partners here."
          linkText="Explore"
          to="/people"
        />
      </div>
    </Section>
  );
};
