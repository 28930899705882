import React from 'react';
import PropTypes from 'prop-types';

export default function Circle(props) {
  return (
    <div className="h-8 w-8 bg-cs-orange-200 rounded-full text-cs-gray-900 flex items-center text-sm justify-center">
      {props.children}
    </div>
  );
}
