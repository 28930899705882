import React from 'react';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function YesBanner(props) {
  const { invitation, creator } = props;

  const color = 'accent';
  const launch = useDialogLauncher();

  function onViewMessages() {
    launch(DialogManager.Messages.Config(creator.id, {
      eventId: invitation.data.eventId
    }));
  }

  function showMaybeDialog() {
    launch(DialogManager.InvitationMaybe.Config(invitation.id));
  }

  function showNoDialog() {
    launch(DialogManager.InvitationNo.Config(invitation.id));
  }

  return (
    <Wrapper
      color={color}
      creator={creator}
      title={`You're attending ${creator.data.firstName}'s event!`}
      subtitle={(
        <>
          Keep the momentum going by responding to messages. If your plans have
          changed, please let {creator.data.firstName} know as soon as possible.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={showNoDialog}>
            I can no longer attend
          </ConnectButton>
          <ConnectButton outline={true} onClick={showMaybeDialog}>
            I'm unsure if I can attend
          </ConnectButton>
          <ConnectButton color={color} onClick={onViewMessages}>
            Go to messages
          </ConnectButton>
      </div>
    </Wrapper>
  );
};
