/**
 * This component is intended to reflect the high level structure of your application,
 * and render any components that are common across all views, such as the header or
 * top-level navigation. All other components should be rendered by route handlers.
 */

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as FullStory from '@fullstory/browser';
import Header from './../components/Header';
import ScrollToTop from './../components/ScrollToTop';
import ErrorBoundary from './ErrorBoundary';
import { useUser } from '@lore/auth';
import { useConfig } from '@lore/config';
import useUserProfileMissingFields from '../hooks/useUserProfileMissingFields';
import IncompleteProfileBanner from './IncompleteProfileBanner';
import useRouter from '../hooks/useRouter';
import Navigation from './Navigation';
import useIntercom from '../hooks/useIntercom';
import Impersonation from './Impersonation';
import useNetwork from '../hooks/useNetwork';
// import AnnouncementBanner from './AnnouncementBanner';
import { Outlet } from 'react-router-dom';
import ScrollAnchorContext from '../context/ScrollAnchorContext';

Layout.propTypes = {
  children: PropTypes.node
};

export default function Layout(props) {
  const { children } = props;
  const { location, history } = useRouter();
  const currentUser = useUser();
  const network = useNetwork();
  const config = useConfig();
  const missingFields = useUserProfileMissingFields();
  const scrollAnchorRef = useRef(null);

  useIntercom();

  useEffect(() => {
    const { enabled } = config.fullstory;

    if (enabled) {
      FullStory.setUserVars({
        uid: currentUser.id,
        displayName: `${currentUser.data.firstName} ${currentUser.data.lastName}`,
        email: currentUser.data.email,
      });
    }
  }, []);

  return (
    <ScrollAnchorContext.Provider value={scrollAnchorRef}>
      <div className="h-screen flex overflow-hiddenx bg-whitex">
        <Impersonation />
        <div className="w-72 hidden lg:block z-10">
          <nav className="flex flex-col -mr-0.5 border-r-2 border-cs-gray-100 py-8 bg-white overflow-y-auto h-screen">
            <Navigation />
          </nav>
        </div>
        <div ref={scrollAnchorRef} className="flex-1 overflow-y-auto">
          {/*<ScrollMemory />*/}
          <ScrollToTop key={`scroll-to-top-${location.pathname}`} />
          {/*<AnnouncementBanner />*/}
          {/*<IncompleteProfileCheck*/}
          {/*  user={currentUser}*/}
          {/*  missingFields={missingFields}*/}
          {/*/>*/}
          <IncompleteProfileBanner />
          <Header />
          {/*<ErrorBoundary key={location.pathname} config={config}>*/}
          <ErrorBoundary config={config}>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </ScrollAnchorContext.Provider>
  );
};
