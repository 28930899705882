import React from 'react';
import Navigation from '../../dialogs_common/Navigation';
import useIntents from '../../hooks/useIntents';
import useRoles from '../../hooks/useRoles';
import Urls from './Urls';
import _ from 'lodash';

export default function useSetupNavigation(step) {
  const { isEducator, isPartner } = useIntents();
  const { isClassroomTeacher } = useRoles();

  function getSteps() {
    if (
      isEducator &&
      isPartner
    ) {
      return _.remove([
        Urls.NETWORK,
        Urls.NAME_CONTACT,
        Urls.ZIP_CODE,
        Urls.PROFILE,
        Urls.SCHOOL,
        isClassroomTeacher ? Urls.TEACHING : undefined,
        isClassroomTeacher ? Urls.PRACTICES : undefined,
        Urls.PAST_ENGAGEMENT,
        Urls.SKILLS_INTRODUCTION,
        Urls.SKILLS,
        // Urls.ACTIVITY_TYPES,
        Urls.EXPERIENCE,
        Urls.HOPE,
        Urls.DEMOGRAPHICS,
        Urls.AVATAR
      ]);
    }

    if (isEducator) {
      return _.remove([
        Urls.NETWORK,
        Urls.NAME_CONTACT,
        Urls.ZIP_CODE,
        Urls.PROFILE,
        Urls.SCHOOL,
        isClassroomTeacher ? Urls.TEACHING : undefined,
        isClassroomTeacher ? Urls.PRACTICES : undefined,
        Urls.PAST_ENGAGEMENT,
        Urls.EXPERIENCE,
        Urls.HOPE,
        Urls.DEMOGRAPHICS,
        Urls.AVATAR
      ]);
    }

    return _.remove([
        Urls.NETWORK,
        Urls.NAME_CONTACT,
        Urls.ZIP_CODE,
        Urls.PROFILE,
        Urls.ORGANIZATION,
        Urls.SKILLS_INTRODUCTION,
        Urls.SKILLS,
        Urls.ACTIVITY_TYPES,
        Urls.EXPERIENCE,
        Urls.HOPE,
        Urls.DEMOGRAPHICS,
        Urls.AVATAR
      ]);
  }

  const steps = getSteps();
  const activeStep = steps.indexOf(step);

  return (
    <Navigation
      activeStep={activeStep}
      steps={steps.length}
    />
  );
};
