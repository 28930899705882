import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Form from './Form';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useUserTags from '../../../hooks/useUserTags';
import useRouter from '../../../hooks/useRouter';
import useSetupNavigation from '../useSetupNavigation';

export default function Layout(props) {
  const { history } = useRouter();
  const user = useUser();

  const navigation = useSetupNavigation(Urls.ACTIVITY_TYPES);

  const activities = useTags(TagTypes.Activity);
  const userActivities = useUserTags(user, TagTypes.Activity);

  if (
    activities.state === PayloadStates.FETCHING ||
    userActivities.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout>
        <Helmet>
          <title>Setup - Activity Types</title>
        </Helmet>
        <Loader />
      </ParentLayout>
    );
  }

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Activity Types</title>
      </Helmet>
      <Form
        user={user}
        activities={activities}
        userActivities={userActivities}
        navigation={navigation}
        onPrevious={(newData) => history.push(Urls.SKILLS)}
        onNext={(newData) => history.push(Urls.EXPERIENCE)}
        onSkip={() => history.push(Urls.EXPERIENCE)}
      />
    </ParentLayout>
  );
};
