import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

TextAlert.propTypes = {
  icon: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default function TextAlert(props) {
  const {
    icon,
    onClick,
    children
  } = props;

  const text = children || (
    <>
      Help others understand your experience by <span className="underline">providing more context</span>
    </>
  );

  return (
    <div
      className={classNames(
        'flex items-centerx space-x-2 py-2 px-3 rounded-lg',
        'bg-cs-yellow-100',
        '-m-px border border-cs-yellow-200 border-opacity-10x',
        'cursor-pointer hover:opacity-80'
      )}
      onClick={onClick}
    >
      {icon && (
        <props.icon className="h-4 w-4 text-cs-yellow-700 mt-1" />
      )}
      <span className="flex-1 text-sm text-cs-yellow-700">
        {text}
      </span>
    </div>
  );
}
