import React from 'react';
import SubSectionHeader from '../../components_profiles/SubSectionHeader';

export default function Section(props) {
  const { title, subtitle, children } = props;

  return (
    <div className="flex flex-col md:flex-row gap-8">
      <div className="flex-1">
        <SubSectionHeader
          title={title}
          subtitle={subtitle}
        />
      </div>
      <div className="flex-1">
        {children}
      </div>
    </div>
  );
};
