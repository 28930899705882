import React from 'react';
import Users from './Users';
import Uploads from './Uploads';
import Header from './Header';
import Summary from './Summary';
import Outcome from './Outcome';
import Ages from './Ages';
import Subjects from './Subjects';
import Card from '../../../components/Card';
import Activity from '../Project/Activity';
import Creator from '../Project/Creator';
import Students from './Students';
import Participation from '../Project/Participation';
import Section from '../../../components_profiles/Section';
import Experience from './Experience';
import Skills from './Skills';
import Testimonials from './sections/Testimonials';
import Photographs from './sections/Photographs';

export default function ProjectPast(props) {
  const { project } = props;

  return (
    <div className="space-y-8">
      <div className="flex flex-col xl:flex-row mb-0x xl:mb-8x gap-8">
        <div className="w-full pr-0 xl:w-2/3 space-y-8">
          <Header project={project} />
          <Summary project={project} />
          <Outcome project={project} />
          <Section title="Activity">
            <Activity project={project}/>
          </Section>
          <Users project={project} />
          <Uploads project={project} />
          <Testimonials project={project} />
          <Photographs project={project} />
        </div>
        <div className="hidden xl:block w-full xl:w-1/3 space-y-8">
          <Card>
            <div className="px-6 py-6 space-y-6">
              <Ages project={project} />
              <Students project={project}/>
              <Subjects project={project} />
              <Skills project={project} />
              <Experience project={project} />
            </div>
          </Card>
          <Creator project={project} />
          <Participation project={project}/>
        </div>
      </div>
    </div>
  );
};
