import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import Intent from './Intent';
import educatorImage from '../../../../assets/images/sign-up-educator.jpg';
import partnerImage from '../../../../assets/images/sign-up-partner.jpg';

Intents.propTypes = {
  userIntents: PropTypes.object
};

export default function Intents(props) {
  const {
    user,
    intents,
    userIntents,
    selectable
  } = props;

  const educatorIntent = _.find(intents.data, intent => intent.data.name === 'Educator');
  const partnerIntent = _.find(intents.data, intent => intent.data.name === 'Partner');

  const isEducator = !!_.find(userIntents.data, intent => intent.data.id === educatorIntent.id);
  const isPartner = !!_.find(userIntents.data, intent => intent.data.id === partnerIntent.id);

  const launch = useDialogLauncher();

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onToggleIntent(intent) {
    const userIntent = _.find(userIntents.data, function(userIntent) {
      return userIntent._tag.id === intent.id;
    });

    if (!userIntent) {
      mutation_create.mutate({
        userId: user.id,
        tagId: intent.id
      }, {
        onSuccess: payload => userIntents._add(payload)
      });
    } else {
      mutation_destroy.mutate(userIntent._tagNode, {
        onSuccess: payload => userIntents._remove(payload)
      });
    }
  }

  function onCreateUserIntent(intent, userIntent) {
    launch(DialogManager.TagNodeCreate.Config(intent.id, {
      modelName: 'user',
      modelId: user.id
    }));
  }

  function onUpdateUserIntent(intent, userIntent) {
    launch(DialogManager.TagNodeUpdate.Config(userIntent._tagNode.id));
  }

  function onDestroyUserIntent(intent, userIntent) {
    launch(DialogManager.TagNodeDestroy.Config(userIntent._tagNode.id));
  }

  function setEducatorIntent(active) {
    if (active && !isEducator) {
      onToggleIntent(educatorIntent);
    }

    if (!active && isEducator) {
      onToggleIntent(educatorIntent);
    }
  }

  function setPartnerIntent(active) {
    if (active && !isPartner) {
      onToggleIntent(partnerIntent);
    }

    if (!active && isPartner) {
      onToggleIntent(partnerIntent);
    }
  }

  return (
    <Overlay isVisible={saving}>
      {/*<div className="flex flex-col md:flex-row space-x-0 md:space-x-12 space-y-12 md:space-y-0">*/}
      <div className="flex flex-col space-x-0 space-y-6">
        <Intent
          title="Find Partners"
          avatar={educatorImage}
          description={(
            <>
              Find connections, opportunities, and partners to bring their skills to support the learners you work with.
            </>
          )}
          guidance={(
            <>
              Great starting point for PreK-12 teachers!
            </>
          )}
          onClick={() => {
            setEducatorIntent(true);
            setPartnerIntent(false);
          }}
          selected={isEducator && !isPartner}
        />
        <Intent
          title="Share"
          avatar={partnerImage}
          description={(
            <>
              Share your knowledge and experiences with educators and learners.
            </>
          )}
          guidance={(
            <>
              Perfect for community members or organizations looking to share skills and/or opportunities.
            </>
          )}
          onClick={() => {
            setEducatorIntent(false);
            setPartnerIntent(true);
          }}
          selected={isPartner && !isEducator}
        />
        <Intent
          title="Find Partners and Share"
          avatar={partnerImage}
          description={(
            <>
              Find connections to support your learners and share your skills as a collaborator on
              other projects.
            </>
          )}
          guidance={(
            <>
              Great option for educational organizations, libraries, school administrators, those
              working with learners outside school settings, or anyone interested in both connecting
              and contributing.
            </>
          )}
          // onClick={() => onToggleIntent(partnerIntent)}
          onClick={() => {
            setEducatorIntent(true);
            setPartnerIntent(true);
          }}
          selected={isEducator && isPartner}
        />
      </div>
    </Overlay>
  );
}
