import React from 'react';
import Card from '../../components/Card';
import { useConnect } from '../../../hooks/@lore/query-connect';
import Avatar from '../../components/Avatar';
import { DateTime } from 'luxon';
import classNames from 'classnames';

export default function Testimonial(props) {
  const { evaluation, onClick } = props;

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  return (
    <Card>
      <div className={classNames(
        'flex p-4 space-x-4',
        onClick && 'hover:bg-cs-orange-50 cursor-pointer'
      )} onClick={onClick}>
        <Avatar size={12} src={user.data.avatarUrl} />
        <div className="flex flex-1 flex-col">
          <div className="flex justify-between">
            <div className="text-lg font-semibold">
              {user.data.firstName} {user.data.lastName}
            </div>
            <div className="text-sm text-gray-500">
              {DateTime.fromISO(evaluation.data.createdAt).toRelative()}
            </div>
          </div>
          <div className="text-sm text-gray-500 space-x-1">
            <span>
              "{evaluation.data.testimonial || '[No testimonial provided]'}"
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
}
