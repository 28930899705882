import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import SelectableIconCard from './SelectableIconCard';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const [data, setData] = useState(_.pick(user.data, [
    'pastEngagement'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // name: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                onPrevious={onPrevious}
                navigation={navigation}
                // title="What's your school or organization?"
                // subtitle="Locations you create are visible only to you"
                alert={error ? (
                  <RequestError request={request} v2={true} />
                ) : showErrors ? (
                  <ErrorMessage v2={true}>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <>
                    <div className="flex flex-col mb-12">
                      <div className="mb-6">
                        <h5 className="text-3xl text-cs-gray-900 font-semibold text-left mb-4">
                          Were you engaging community partners in your curriculum/events before
                          CommunityShare was available to you?
                        </h5>
                        {/*<h6 className="font-normal mt-2 text-cs-gray-800 text-left text-lg mb-4">*/}
                        {/*  Knowing the activities you're interested in will help us connect you to educators interested in similar activities.*/}
                        {/*</h6>*/}
                      </div>
                      <div className="space-y-4">
                        <SelectableIconCard
                          name="Yes"
                          selected={data.pastEngagement === true}
                          onClick={() => onChange('pastEngagement', true)}
                        />
                        <SelectableIconCard
                          name="No"
                          selected={data.pastEngagement === false}
                          onClick={() => onChange('pastEngagement', false)}
                        />
                      </div>
                    </div>
                  </>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
