import React from 'react';
import ProfileMissingDialog from '../../dialogs/profile.missing';
import { useUser } from '@lore/auth';
import useIntents from '../../hooks/useIntents';

export default function Launcher(props) {
  const currentUser = useUser();

  const { isEducator } = useIntents();

  return (
    <ProfileMissingDialog
      user={currentUser}
      title="Ready to complete your profile?"
      subtitle={isEducator ?
        `This will allow you to create projects/events and invite partners to participate. You'll also be able to express interest in offers.` :
        `This will allow educators to discover you and invite you to projects/events. You'll also be able to create offers.`
      }
      onCancel={props.onClose}
    />
  );
};
