import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import useTags from '../../../hooks/useTags';
import TextContentSection from '../../../components_profiles/TextContentSection';

Experience.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Experience(props) {
  const { project } = props;

  const projectExperiences = useProjectTags(project, TagTypes.Experience);

  if (projectExperiences.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (projectExperiences.data.length === 0) {
    return null;
  }

  return (
    <TextContentSection title="Desired life experience">
      <ul className="list-disc ml-6">
        {projectExperiences.data.map(function(tag) {
          return (
            <li key={tag.id || tag.cid}>
              {tag.data.name}
            </li>
          );
        })}
      </ul>
    </TextContentSection>
  );
};
