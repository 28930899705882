import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import Communities from './Communities';
import { Helmet } from 'react-helmet-async';
import useRouter from '../../../hooks/useRouter';
import useSetupNavigation from '../useSetupNavigation';

export default function Layout(props) {
  const { history } = useRouter();
  const user = useUser();

  const navigation = useSetupNavigation(Urls.NETWORK);

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Community</title>
      </Helmet>
      <Communities
        user={user}
        navigation={navigation}
        // onPrevious={(newData) => history.push(Urls.NAME)}
        onNext={(newData) => history.push(Urls.NAME_CONTACT)}
        onSkip={() => history.push(Urls.PROFILE)}
      />
    </ParentLayout>
  );
};
