import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import Avatar from '../../../components/Avatar';

Question.propTypes = {
  question: PropTypes.object.isRequired
};

export default function Question(props) {
  const { question, onClick } = props;

  const user = useConnect('user.byId', {
    id: question.data.userId
  });

  return (
    <Avatar
      size={8}
      src={user.data.avatarUrl}
      tooltip={`${user.data.firstName} ${user.data.lastName}`}
      onClick={() => onClick(user)}
    />
  );
}
