import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import Profile from './Profile';
import { Helmet } from 'react-helmet-async';
import useRouter from '../../../hooks/useRouter';
import useIntents from '../../../hooks/useIntents';
import useSetupNavigation from '../useSetupNavigation';

export default function Layout(props) {
  const { history } = useRouter();
  const user = useUser();

  const navigation = useSetupNavigation(Urls.PROFILE);

  const { isEducator, isPartner } = useIntents();

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Profile</title>
      </Helmet>
      <Profile
        user={user}
        navigation={navigation}
        // onPrevious={(newData) => history.push(Urls.ZIP_CODE)}
        // onNext={(newData) => history.push(Urls.NAME)}
        // onSkip={() => history.push(Urls.NAME)}
        onPrevious={(newData) => history.push(Urls.ZIP_CODE)}
        onNext={() => isEducator ? history.push(Urls.SCHOOL) : history.push(Urls.ORGANIZATION)}
        onSkip={() => isEducator ? history.push(Urls.SCHOOL) : history.push(Urls.ORGANIZATION)}
      />
    </ParentLayout>
  );
};
