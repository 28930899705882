import React  from 'react';
import { useConnect } from '@lore/query-connect';
import { PhotoIcon } from '@heroicons/react/24/outline';
import Tooltip from '../../_post-opportunity/forms/Tooltip';
import { PayloadStates } from '@lore/utils';

export default function Images(props) {
  const { project } = props;

  const uploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          'evaluationProjectEducator.projectId': project.id
        }
      }
    }
  });

  return (
    <div className="flex items-center space-x-1">
      <Tooltip tooltip="Photos" icon={false}>
        <PhotoIcon className="w-5 h-5 text-gray-500" />
      </Tooltip>
      <div className="text-sm text-gray-500">
        {uploads.state === PayloadStates.FETCHING ? '...' : uploads.meta.totalCount}
      </div>
    </div>
  );
}
