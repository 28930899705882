export default {
  Activity: 'Activity',
  ActivityType: 'ActivityType',
  Age: 'Age',
  Commitment: 'Commitment',
  Day: 'Day',
  Expertise: 'Expertise',
  ExpertiseType: 'ExpertiseType',
  Experience: 'Experience',
  Grade: 'Grade',
  Group: 'Group',
  Guidance: 'Guidance',
  Intent: 'Intent',
  InteractionType: 'InteractionType',
  Location: 'Location',
  Participant: 'Participant',
  Practice: 'Practice',
  Size: 'Size',
  Subject: 'Subject',
  Supply: 'Supply',
  Time: 'Time'
}
