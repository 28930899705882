import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
// import ProjectBookmark from '../../../components/ProjectBookmark';
import { useUser } from '@lore/auth';
import Date from '../../projects/Project/Date';
import HeaderIcon from './HeaderIcon';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import Images from '../../projects-past/Project/Images';
import Testimonials from '../../projects-past/Project/Testimonials';
import School from '../../projects/Project/School';

Header.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Header(props) {
  const { project } = props;

  const currentUser = useUser();

  const site = useConnect('site.byId', {
    id: project.data.siteId
  });

  return (
    <div className="relative">
      {/*<div className="h-64 rounded-cs-10 overflow-hidden w-full relative bg-cs-gray-500  mb-4">*/}
      {/*  <div className="absolute z-10 top-2 right-4">*/}
      {/*    <ProjectBookmark*/}
      {/*      project={project}*/}
      {/*      user={currentUser}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*  <img*/}
      {/*    className="absolute left-0 top-0 object-cover object-center h-full w-full"*/}
      {/*    src={project.data.imageUrl}*/}
      {/*    alt="image"*/}
      {/*  />*/}
      {/*</div>*/}
      <div className="text-3xl text-cs-gray-900 font-semibold mb-2">
        {project.data.title}
      </div>
      <div className="flex items-center space-x-4">
        <Date project={project} />
        <School project={project} />
        <Images project={project} />
        <Testimonials project={project} />
      </div>
    </div>
  );
};
