import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useUserTags from '../../../hooks/useUserTags';
import Intents from './Intents';

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Profile(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const intents = useTags(TagTypes.Intent);
  const userIntents = useUserTags(user, TagTypes.Intent);

  const [data, setData] = useState(_.pick(user.data, [
    'isEducator'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // isEducator: [validators.boolean.isRequired],
          userIntents: [function(value) {
            if (userIntents.data.length < 1) {
              return 'Must select at least one intent';
            }
          }]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                className="w-full"
                onPrevious={onPrevious}
                navigation={navigation}
                // title={(
                //   <>
                //     {user.data.firstName ? `Welcome ${user.data.firstName.trim()}!` : 'Welcome!'} Tell us about how you want to get involved.
                //   </>
                // )}
                title="Tell us about how you want to get involved."
                subtitle={(
                  <div className="space-y-2">
                    <div>
                      Everyone has valuable knowledge that can transform lives and enrich our communities.
                    </div>
                    <div>
                      Which best describes your reason for joining CommunityShare today?
                    </div>
                    <div>
                      <span className="text-cs-orange-500">*</span>By using CommunityShare, you agree to
                      our <a className="underline text-cs-orange-500" target="_blank"
                             href="https://www.communityshare.org/terms-of-use/">Terms of Use</a>
                      {' and '}
                      <a className="underline text-cs-orange-500" target="_blank"
                         href="https://www.communityshare.org/privacy-policy/">Privacy Policy</a>.
                    </div>
                  </div>
                )}
                alert={error ? (
                  <RequestError request={request} v2={true} />
                ) : showErrors ? (
                  <ErrorMessage v2={true}>
                    Please select an intent.
                  </ErrorMessage>
                ) : null}
                body={(
                  <Intents
                    user={user}
                    intents={intents}
                    userIntents={userIntents}
                  />
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
