import React from 'react';
import { PayloadStates } from '@lore/utils';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { useUser } from '@lore/auth';
import classNames from 'classnames';
import useBookmark from '../../hooks/useBookmark';
import Bookmark from '../../components/Bookmark';
import VirtualAvatar from '../../components/User/VirtualAvatar';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import Card from '../../components/Card';
import UserRoles from './UserRoles';
import UserJobs from './UserJobs';
import useIntents from '../../hooks/useIntents';

function getExtra(project, event, offer) {
  if (project) {
    return {
      modelName: 'project',
      modelId: project.id
    };
  }

  if (event) {
    return {
      modelName: 'event',
      modelId: event.id
    };
  }

  if (offer) {
    return {
      modelName: 'offer',
      modelId: offer.id
    };
  }
}

export default function User(props) {
  const { user, selected = false, footer, project, event, offer } = props;

  const launch = useDialogLauncher();
  const currentUser = useUser();
  const { isEducator } = useIntents(user);

  const userBookmark = useBookmark({user, currentUser});

  function onClick() {
    launch(DialogManager.User.Config(user.id, getExtra(project, event, offer)));
  }

  return (
    <Card
      className={classNames(
        // 'shadow-cs-flat rounded-2xl p-4 relative z-10 -m-0.5 border-2 border-cs-gray-100',
        'p-4 relative z-10',
        selected ? 'bg-cs-orange-50' : 'bg-white hover:bg-cs-orange-50 cursor-pointer'
      )}
      onClick={selected ? undefined : onClick}
    >
      <div className="absolute z-20 top-2 right-4">
        {userBookmark.state === PayloadStates.RESOLVED && (
          <Bookmark selected={true}/>
        )}
      </div>
      <div className="flex flex-col items-center h-full space-y-4">
        <VirtualAvatar size={24} user={user}/>
        <div className="flex-1 flex flex-col items-center space-y-0.5">
          <div className="text-cs-gray-900 text-lg font-semibold text-center">
            {user.data.firstName} {user.data.lastName}
          </div>
          {isEducator ? (
            <UserRoles user={user} />
          ) : (
            <UserJobs user={user} />
          )}
        </div>
        {footer ? (
          <div className="text-lg text-cs-orange-500 font-semibold">
            {footer}
          </div>
        ) : (
          <div className="flex items-start text-base">
            <div className="mt-0.5">
              <MapPinIcon className="text-cs-gray-500 mr-1 h-4 w-4"/>
            </div>
            <div className="text-sm text-cs-gray-500">
              {user.data.city && user.data.state ?
                `${user.data.city}, ${user.data.state}` :
                user.data.zipCode
              }
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
