import React from 'react';
import PropTypes from 'prop-types';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Avatar from '../../../components/Avatar';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import Circle from './Circle';
import Question from './Question';

Questions.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Questions(props) {
  const { project } = props;

  const MAX_CONVERSATIONS = 1000;

  const launch = useDialogLauncher();

  function onClick(user) {
    launch(DialogManager.User.Config(user.id));
  }

  const responses = [
    'Connect',
    // 'Pending'
  ];

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          response: {
            $in: responses
          }
        }
      }
    }
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    questions.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader/>
    );
  }

  if (questions.data.length > 0) {
    return (
      <>
        <Avatar
          size={8}
          src={creator.data.avatarUrl}
          tooltip={`${creator.data.firstName} ${creator.data.lastName}`}
          onClick={() => onClick(creator)}
        />
        {questions.data.slice(0,MAX_CONVERSATIONS).map(function(question, index) {
          return (
            <Question
              key={index}
              question={question}
              onClick={(user) => onClick(user)}
            />
          );
        })}
        {questions.data.slice(MAX_CONVERSATIONS).length > 0 && (
          <Circle>
            +{questions.data.slice(MAX_CONVERSATIONS).length}
          </Circle>
        )}
      </>
    );
  }

  return null;
}
