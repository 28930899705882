import React from 'react';
import PropTypes from 'prop-types';
import ClampableText from '../../../components/ClampableText';
import Subjects from './Subjects';
import Ages from './Ages';
import Activities from './Activities';
import SectionHeader from '../../../components_profiles/SectionHeader';

Summary.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Summary(props) {
  const { project } = props;

  return (
    <div>
      <div className="space-y-2">
        <SectionHeader
          title="Summary"
        />
        <div className="text-base text-cs-gray-500">
          <ClampableText markup={true}>
            {project.data.interactionDescription || '[No summary provided]'}
          </ClampableText>
        </div>
      </div>
      <div className="block xl:hidden px-6 pb-6 space-y-6">
        {/*<Date project={project} />*/}
        <Ages project={project} />
        <Activities project={project} />
        <Subjects project={project} />
      </div>
    </div>
  );
};
