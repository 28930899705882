import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';
import useSetupNavigation from '../useSetupNavigation';

export default function Layout(props) {
  const { history } = useRouter();
  const user = useUser();

  const navigation = useSetupNavigation(Urls.ABOUT);

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - About</title>
      </Helmet>
      <Form
        user={user}
        navigation={navigation}
        onPrevious={(newData) => history.push(Urls.ZIP_CODE)}
        onNext={(newData) => history.push(Urls.SKILLS)}
        onSkip={() => history.push(Urls.SKILLS)}
      />
    </ParentLayout>
  );
};
