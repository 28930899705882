import React from 'react';
import { useConnect } from '@lore/query-connect';
import Resource from '../../../components_profiles/Resource';
import SectionHeader from '../../../components_profiles/SectionHeader';

export default function Uploads(props) {
  const { project } = props;

  const uploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id
        }
      }
    }
  });

  if (uploads.data.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      <SectionHeader
        title="Resources"
        subtitle="Things you can view or download such as articles, websites, lesson plans, photos of student work, etc."
      />
      <div className="grid grid-cols-1 md:grid-cols-2x lg:grid-cols-3x xl:grid-cols-2x gap-6">
        {uploads.data.map(function(upload, index) {
          return (
            <Resource
              key={index}
              title={upload.data.name}
              description={upload.data.description}
              url={upload.data.url}
            />
          );
        })}
      </div>
    </div>
  );
};
