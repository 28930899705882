import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import FormTemplate from './FormTemplate';
import SolidButton from '../../components/Button/SolidButton';
import useUserProfileMissingFields from '../../hooks/useUserProfileMissingFields';
import User from '../../pages/people-partners/User';
import useIntents from '../../hooks/useIntents';

Confirmation.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired
};

export default function Confirmation(props) {
  const {
    user,
    onNext,
    onPrevious,
    navigation
  } = props;

  const { isEducator } = useIntents();

  const missingFields = useUserProfileMissingFields();

  if (!missingFields) {
    return null;
  }

  const allMissingFields = missingFields.profile.concat(missingFields.interests);
  let missingFieldNames ='';

  if (allMissingFields.length > 0) {
    if (allMissingFields.length === 1) {
      missingFieldNames = allMissingFields[0].name;
    } else if (allMissingFields.length === 2) {
      missingFieldNames = `${allMissingFields[0].name} and ${allMissingFields[1].name}`;
    } else {
      missingFieldNames = `${allMissingFields.slice(0, allMissingFields.length - 1).map(function(field) {
        return field.name;
      }).join(', ')}, and ${allMissingFields[allMissingFields.length - 1].name}`;
    }
  }

  const userCard = (
    <div className="p-0.5 rounded-2xl border-cs-orange-500 border-2 max-w-xs mx-auto">
      <User user={user} />
    </div>
  );

  if (
    missingFields &&
    (missingFields.profile.length + missingFields.interests.length) > 0
  ) {
    return (
      <DialogTemplate className="w-screen max-w-xl">
        <FormTemplate
          navigation={navigation}
          onPrevious={onPrevious}
          title={isEducator ?
            `Your profile is ${100 - Math.ceil(100 * allMissingFields.length/3)}% complete!` :
            `Your profile is ${100 - Math.ceil(100 * allMissingFields.length/4)}% complete!`
          }
          // subtitle="Thanks for completing your profile and allowing us to give you an introduction."
          body={(
            <>
              <div className="text-left text-cs-gray-900 font-light max-w-xl m-auto mb-16">
                <p>
                  We noticed you chose to skip some of the steps, and that's totally okay! Just a friendly
                  reminder that <span className="font-semibold">you will not be able to message others or show up in
                  search results until you complete your profile</span>.
                </p>
                <p>
                  You can complete your profile at any time by selecting "Edit My Profile" from within the
                  platform and adding your <span className="font-semibold">{missingFieldNames}</span>. If you'd
                  like to preview your current profile click the icon below.
                </p>
              </div>
              {userCard}
            </>
          )}
          footer={(
            <div className="flex items-center justify-end">
              <SolidButton onClick={onNext}>
                Close
              </SolidButton>
            </div>
          )}
        />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <FormTemplate
        onPrevious={onPrevious}
        title="Congratulations, your profile is complete!"
        // subtitle="Thanks for completing your profile and allowing us to give you an introduction."
        body={(
          <>
            <div className="text-left text-cs-gray-900 font-light max-w-xl m-auto mb-12">
              <p>
                You can preview your profile by clicking the icon below.
              </p>
            </div>
            {userCard}
            <div className="text-left text-cs-gray-900 font-light max-w-xl m-auto mb-12">
              Your profile will now show up in search results and you can message others.
            </div>
          </>
        )}
        footer={(
          <div className="flex items-center justify-end">
            <SolidButton onClick={onNext}>
              Close
            </SolidButton>
          </div>
        )}
      />
    </DialogTemplate>
  );
};
