import React from 'react';
import { Helmet } from 'react-helmet-async';
import Projects from './Projects';
import useRouter from '../../hooks/useRouter';
import PageHeader from '../people-people/PageHeader';

export default function Layout(props) {
  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore past projects</title>
      </Helmet>
      <PageHeader
        title="Explore past projects"
        subtitle={(
          <>
            Check out past inspiring projects and events where partners contributed their
            skills and experiences to make a difference. Use filters to find projects and
            events that interest you.
          </>
        )}
      />
      <Projects
        router={router}
      />
    </div>
  );
}
