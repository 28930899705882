import React from 'react';
import { useConnect } from '@lore/query-connect';
import Entry from '../../../user/User/Entry';
import classNames from 'classnames';
import getUrlForUpload from '../../../user/User/getUrlForUpload';
// import ImageChildBuilder from '../../../evaluations/_common/ImageChildBuilder';
import Images from '../../../story/Story/Images';
import EmptyState from '../../../../components/EmptyState';
import { PhotoIcon } from '@heroicons/react/24/outline';
import Section from '../../../../components_profiles/Section';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../../components/Loader';

export default function Photographs(props) {
  const { project } = props;

  const evaluationUploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          'evaluationProjectEducator.projectId': project.id
        }
      }
    }
  });

  if (evaluationUploads.state === PayloadStates.FETCHING) {
    return (
      <Section
        title="Photos"
        // subtitle="Uploaded by the educator during their evaluation."
      >
        <Loader className="mx-7" />
      </Section>
    );
  }

  if (evaluationUploads.data.length === 0) {
    return null;
    // return (
    //   <EmptyState
    //     icon={PhotoIcon}
    //     title="No images were uploaded."
    //   />
    // );
  }

  return (
    <Section
      title="Photos"
      // subtitle="Uploaded by the educator during their evaluation."
    >
      <div className="space-y-4">
        <Images
          className={classNames(
            'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4',
            evaluationUploads._isPreviousData && 'opacity-50'
          )}
          imageHeight="h-64 sm:h-64 lg:h-64"
          images={{
            data: evaluationUploads.data.map(function(upload) {
              const url = getUrlForUpload(upload);

              return {
                id: upload.id,
                data: {
                  name: upload.data.name,
                  imageUrl: url,
                  // width: 0,
                  // height: 0
                },
                _upload: upload
              };
            })
          }}
        />
      </div>
    </Section>
  );
}
