import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Form from './Form';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useUserTags from '../../../hooks/useUserTags';
import useRouter from '../../../hooks/useRouter';
import useSetupNavigation from '../useSetupNavigation';

export default function Layout(props) {
  const { history } = useRouter();
  const user = useUser();

  const navigation = useSetupNavigation(Urls.TEACHING);

  const subjects = useTags(TagTypes.Subject);
  const userSubjects = useUserTags(user, TagTypes.Subject);
  const grades = useTags(TagTypes.Grade);
  const userGrades = useUserTags(user, TagTypes.Grade);

  if (
    subjects.state === PayloadStates.FETCHING ||
    userSubjects.state === PayloadStates.FETCHING ||
    grades.state === PayloadStates.FETCHING ||
    userGrades.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout>
        <Helmet>
          <title>Setup - Teaching</title>
        </Helmet>
        <Loader />
      </ParentLayout>
    );
  }

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Teaching</title>
      </Helmet>
      <Form
        user={user}
        subjects={subjects}
        userSubjects={userSubjects}
        grades={grades}
        userGrades={userGrades}
        navigation={navigation}
        onPrevious={(newData) => history.push(Urls.SCHOOL)}
        onNext={(newData) => history.push(Urls.PRACTICES)}
        onSkip={() => history.push(Urls.PRACTICES)}
      />
    </ParentLayout>
  );
};
