import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import useTags from '../../../hooks/useTags';
import TextContentSection from '../../../components_profiles/TextContentSection';

Ages.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Ages(props) {
  const { project } = props;

  const ages = useTags(TagTypes.Age);
  const projectAges = useProjectTags(project, TagTypes.Age);

  if (projectAges.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <TextContentSection title="Age groups">
      <ul className="list-disc ml-6">
        {ages.data.map(function(age) {
          const projectAge = _.find(projectAges.data, function(projectAge) {
            return projectAge._tag.id === age.id;
          });

          if (!projectAge) {
            return null;
          }

          return (
            <li key={age.id || age.cid}>
              {age.data.name}
            </li>
          );
        })}
      </ul>
    </TextContentSection>
  );
};
