import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { useConnect } from '../../hooks/@lore/query-connect';
import _ from 'lodash';

export default function useRoles(props) {
  const currentUser = useUser();

  const roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: currentUser.id
        }
      }
    }
  });

  if (roles.state === PayloadStates.FETCHING) {
    return {};
  }

  const isClassroomTeacher = !!_.find(roles.data, m => m.data.title === 'Classroom Teacher');

  return {
    isClassroomTeacher
  };
};
