import React from 'react';
import Markdown from './Markdown';
import useNetwork from '../hooks/useNetwork';
import Banner from './Banner';
import useIntents from '../hooks/useIntents';

export default function AnnouncementBanner(props) {
  const { isEducator, isPartner } = useIntents();

  const network = useNetwork();

  const banners = [];

  if (
    isEducator &&
    isPartner &&
    network.data.educatorBanner === network.data.partnerBanner
  ) {
    return (
      <Banner key="educator-partner-banner">
        <Markdown white={true} text={network.data.educatorBanner} />
      </Banner>
    );
  }

  if (
    isEducator &&
    network.data.educatorBanner
  ) {
    banners.push(
      <Banner key="educator-banner">
        <Markdown white={true} text={network.data.educatorBanner} />
      </Banner>
    );
  }

  if (
    isPartner &&
    network.data.partnerBanner
  ) {
    banners.push(
      <Banner key="partner-banner">
        <Markdown white={true} text={network.data.partnerBanner} />
      </Banner>
    );
  }

  if (banners.length > 0) {
    return banners;
  }

  return null;
};
