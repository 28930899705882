import React from 'react';
import { useUser } from '@lore/auth';
import Name from './Name';
import About from './About';
import ZipCode from './ZipCode';
import Timezone from './Timezone';
import Organizations from './Organizations';
import Sites from './Sites';
import Subjects from './Subjects';
import Website from './Website';
import LinkedIn from './LinkedIn';
import Twitter from './Twitter';
import Grades from './Grades';
import Practices from './Practices';
import Demographics from './Demographics';
import Phone from './Phone';
import Opportunities from './Opportunities';
import Avatar from './Avatar';
import Skills from './Skills';
import Ages from './Ages';
import Activities from './Activities';
import Groups from './Groups';
import Experiences from './Experiences';
import ExperienceCards from './ExperienceCards';
import Guidances from './Guidances';
import Section from './Section';
import Divider from '../../forms/_fields_v2/Divider';
import { Helmet } from 'react-helmet-async';
import MissingFields from '../profile/MissingFields';
import GuidanceCards from './GuidanceCards';
import ExpertiseCards from './ExpertiseCards';
// import SectionTitle from '../home/SectionTitle';
import SectionTitle from '../my-network/Section';
import { AcademicCapIcon, UsersIcon } from '@heroicons/react/24/outline';
import InfoCard from '../people-people/InfoCard';

export default function DialogLayout(props) {
  const currentUser = useUser();

  return (
    <>
      <div className="space-y-8 mb-24x">
        {/*<SectionTitle*/}
        {/*  title="Common Fields"*/}
        {/*  // subtitle="asdf"*/}
        {/*/>*/}
        {/*<Divider />*/}
        <Section
          title="Basic Information"
          subtitle={(
            <>
              Increase the likelihood of connecting by adding a photo and introduction of yourself.
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-6">
            <Name user={currentUser} />
            <About user={currentUser} />
            <Avatar user={currentUser} />
            <Phone user={currentUser} />
            <Opportunities user={currentUser} />
          </div>
        </Section>
        <Divider />
        <Section
          title="Location and timezone"
          subtitle={(
            <>
              Match with people and opportunities near you and show the right times for your location.
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-4">
            <ZipCode user={currentUser} />
            <Timezone user={currentUser} />
          </div>
        </Section>
        <Divider />
        <Section
          title="Website and social media"
          subtitle="Allow potential collaborators to learn more about you."
        >
          <div className="grid grid-cols-1 gap-4">
            <Website user={currentUser} />
            <LinkedIn user={currentUser} />
            {/*<Twitter user={currentUser} />*/}
          </div>
        </Section>
        <Divider />
        <Section
          title="Demographics"
          subtitle={(
            <>
              Help measure our progress towards being representative of our broader community's diversity.
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-6">
            <Demographics user={currentUser} />
          </div>
        </Section>
        <Divider />
        <Section
          title="Life experiences"
          subtitle={(
            <>
              Identify experiences and aspects of yourself you are willing to share with students and educators.
            </>
          )}
        >
          <div className="grid grid-cols-1">
            <ExperienceCards user={currentUser} />
          </div>
        </Section>
        <Divider />
      </div>
      <div className="space-y-8">
        <InfoCard
          icon={UsersIcon}
          title="Partner-specific fields"
          subtitle={(
            <>
              These fields are relevant if you want to volunteer your skills and experience
              as part of experiences for learners.
            </>
          )}
        />
        {/*<SectionTitle*/}
        {/*  title="Partner-specific fields"*/}
        {/*  subtitle={(*/}
        {/*    <>*/}
        {/*      These fields are relevant if you want to volunteer your skills and experience*/}
        {/*      as part of experiences for learners.*/}
        {/*    </>*/}
        {/*  )}*/}
        {/*/>*/}
        <Divider />
        <Section
          title="Affiliations"
          subtitle="Identify organizations where you work or serve to help educators find you."
        >
          <div className="grid grid-cols-1 gap-4">
            <Organizations user={currentUser} />
          </div>
        </Section>
        <Divider />
        <Section
          title="Past and present work experiences"
          subtitle={(
            <>
              Identify topics and experiences you are willing to share or explore with students or educators.
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-4">
            {/*<Skills user={currentUser} />*/}
            <ExpertiseCards user={currentUser} />
          </div>
        </Section>
        <Divider />
        <Section
          title="Engagement preferences"
          subtitle={(
            <>
              Specify if you are passionate about serving particular audiences.
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-4">
            <Ages user={currentUser} />
            <Activities user={currentUser} />
            <Groups user={currentUser} />
          </div>
        </Section>
        <Divider />
        <Section
          title="Professional competencies"
          subtitle={(
            <>
              Share if you're able to connect with students on frequently needed topics.
            </>
          )}
        >
          <div className="grid grid-cols-1">
            <GuidanceCards user={currentUser} />
          </div>
        </Section>
        <Divider />
      </div>
      <div className="space-y-8">
        <InfoCard
          icon={AcademicCapIcon}
          title="Educator-specific fields"
          subtitle={(
            <>
              These fields are relevant if you want to create experiences for a group
              of learners and invite partners to participate.
            </>
          )}
        />
        {/*<SectionTitle*/}
        {/*  title="Educator-specific fields"*/}
        {/*  subtitle={(*/}
        {/*    <>*/}
        {/*      These fields are relevant if you want to create experiences for a group*/}
        {/*      of learners and invite partners to participate.*/}
        {/*    </>*/}
        {/*  )}*/}
        {/*/>*/}
        <Divider />
        <Section
          title="Where your learners are located"
          subtitle="Add your school(s) or learning site here to make sharing a project faster."
        >
          <div className="grid grid-cols-1 gap-4">
            <Sites user={currentUser} />
          </div>
        </Section>
        <Divider />
        <Section
          title="Teaching"
          subtitle={(
            <>
              Get opportunities most relevant to your subject, grades, and professional learning interests.
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-4">
            <Subjects user={currentUser} />
            <Grades user={currentUser} />
            <Practices user={currentUser} />
          </div>
        </Section>
      </div>
    </>
  );
};
