import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Form from './Form';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useUserTags from '../../../hooks/useUserTags';
import useRouter from '../../../hooks/useRouter';
import useSetupNavigation from '../useSetupNavigation';

export default function Layout(props) {
  const { history } = useRouter();

  const user = useUser();

  const navigation = useSetupNavigation(Urls.INTERESTS);

  const groups = useTags(TagTypes.Group);
  const userGroups = useUserTags(user, TagTypes.Group);
  const ages = useTags(TagTypes.Age);
  const userAges = useUserTags(user, TagTypes.Age);

  if (
    groups.state === PayloadStates.FETCHING ||
    userGroups.state === PayloadStates.FETCHING ||
    ages.state === PayloadStates.FETCHING ||
    userAges.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout>
        <Helmet>
          <title>Setup - Interests</title>
        </Helmet>
        <Loader />
      </ParentLayout>
    );
  }

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Interests</title>
      </Helmet>
      <Form
        user={user}
        groups={groups}
        userGroups={userGroups}
        ages={ages}
        userAges={userAges}
        navigation={navigation}
        onPrevious={(newData) => history.push(Urls.SKILLS)}
        onNext={(newData) => history.push(Urls.MOTIVATION)}
        onSkip={() => history.push(Urls.MOTIVATION)}
      />
    </ParentLayout>
  );
};
