import React from 'react';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';

export default function Header(props) {
  const launch = useDialogLauncher();

  function onSiteMissing() {
    launch(DialogManager.SiteMissing.Config());
  }

  return (
    <div className="flex flex-col">
      <div className="mb-6">
        <h5 className="text-3xl text-cs-gray-900 font-semibold text-left">
          Share where your learners are located.
        </h5>
        <h6 className="font-normal mt-2 text-cs-gray-800 text-left text-lg mb-4 space-y-2">
          <div>
            Please share the school or site where you work with your learners so we can help connect
            you with the right partners and opportunities.
          </div>
          <div>
            You can select multiple locations if you work in more than one place. If you're based at
            a district office, we recommend selecting the district name for now or the site where you're
            most likely to collaborate with partners. You can update or change your selections anytime.
          </div>
          <div>
            Can't find your school or site on the list? Or do you work at a non-school educational
            location? <span className="text-cs-orange-500 hover:text-cs-yellow-500 cursor-pointer underline" onClick={onSiteMissing}>Click here for help!</span>
          </div>
        </h6>
      </div>
    </div>
  );
};
