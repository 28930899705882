import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import useUserTags from './useUserTags';
import TagTypes from '../constants/TagTypes';
import useTags from './useTags';
import _ from 'lodash';

export default function useIntents(user) {
  const currentUser = useUser();

  const intents = useTags(TagTypes.Intent);
  const userIntents = useUserTags(user || currentUser, TagTypes.Intent);

  if (
    intents.state === PayloadStates.FETCHING ||
    userIntents.state === PayloadStates.FETCHING
  ) {
    return {};
  }

  const educatorIntent = _.find(intents.data, intent => intent.data.name === 'Educator');
  const isEducator = !!_.find(userIntents.data, intent => intent.data.id === educatorIntent.id);

  const partnerIntent = _.find(intents.data, intent => intent.data.name === 'Partner');
  const isPartner = !!_.find(userIntents.data, intent => intent.data.id === partnerIntent.id);

  return {
    isEducator,
    isPartner
  };
};
