import React, { Fragment } from 'react';
import { useUser } from '@lore/auth';
import Section from '../../my-network/Section';
import Option from '../../my-network/Option';
import Divider from '../../../forms/_fields_v2/Divider';
import Card from '../../../components/Card';
import {
  ClockIcon,
  EnvelopeIcon,
  FlagIcon,
  FolderIcon,
  NewspaperIcon,
  UserIcon,
  UsersIcon
} from '@heroicons/react/24/outline';
import usePendingInvitationsCount from '../../my-invitations/usePendingInvitationsCount';
import usePendingEvaluationsCount from '../../my-evaluations/usePendingEvaluationsCount';
import useMissingFields from '../../profile/useMissingFields';
import usePendingExpiredCount from '../../my-expired/usePendingExpiredCount';
import OptionCard from '../../my-network/OptionCard';
import useDraftOpportunityCount from '../../my-expired/useDraftOpportunityCount';
import useActiveOpportunityCount from '../../my-expired/useActiveOpportunityCount';
import useActiveInvitationsCount from '../../my-participating/useActiveInvitationsCount';
import useActiveOffers from '../../my-leading/useActiveOffers';
import Images from '../../../constants/Images';
import _ from 'lodash';
import Loader from '../../../components/Loader';

export default function Involvement(props) {
  const currentUser = useUser();

  const draftOpportunityCount = useDraftOpportunityCount() || 0;
  const activeOpportunityCount = useActiveOpportunityCount() || 0;
  const activeInvitationsCount = useActiveInvitationsCount() || 0;
  // const activeInvitationsCount = useActiveOffers() || 0;

  const opportunityCount = (
    draftOpportunityCount +
    activeOpportunityCount
  );

  const totalCount = (
    opportunityCount +
    activeInvitationsCount
  );

  // if (
  //   !_.isNumber(totalCount) ||
  //   totalCount === 0
  // ) {
  //   return null;
  // }

  if (!_.isNumber(totalCount)) {
    return (
      <Loader className="mx-7" />
    );
  }

  if (totalCount === 0) {
    return null;
  }

  return (
    <Section
      title="Your current activities"
      subtitle={(
        <>
          View the projects, events, and offers you're currently leading or involved with here.
        </>
      )}
    >
      {/*<div className="grid grid-cols-2 gap-4">*/}
      {/*  {opportunityCount > 0 && (*/}
      {/*    <OptionCard*/}
      {/*      icon={FlagIcon}*/}
      {/*      title={`You're leading ${opportunityCount} ${opportunityCount > 1 ? 'activities' : 'activity'}`}*/}
      {/*      // subtitle="These are active projects, events, or offers that you created (and are available for educators and partners to participate in)."*/}
      {/*      linkText="Manage"*/}
      {/*      to="/my-leading"*/}
      {/*      count={opportunityCount}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*  {activeInvitationsCount && (*/}
      {/*    <OptionCard*/}
      {/*      icon={UsersIcon}*/}
      {/*      title={`You're collaborating on ${activeInvitationsCount} ${activeInvitationsCount > 1 ? 'activities' : 'activity'} created by others`}*/}
      {/*      // subtitle="These are projects or events you that you expressed a desire to support or directly participate in."*/}
      {/*      linkText="View"*/}
      {/*      to="/my-participating"*/}
      {/*      count={activeInvitationsCount}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</div>*/}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3x gap-4">
        {opportunityCount > 0 && (
          <OptionCard
            // imageUrl={bookshelfImage}
            imageUrl={Images.PENCIL_WRITING_ON_LIST}
            title={(
              <>
                You're leading <span
                className="text-cs-orange-500 font-semibold">{opportunityCount}</span> {opportunityCount > 1 ? 'activities' : 'activity'}
              </>
            )}
            subtitle="Invite participants, make updates, and manage your activities."
            linkText="Manage"
            to="/my-leading"
          />
        )}
        {activeInvitationsCount > 0 && (
          <OptionCard
            // imageUrl={bookshelfImage}
            imageUrl={Images.HANDS_IN_A_CIRCLE}
            title={(
              <>
                You're collaborating on <span className="text-cs-orange-500 font-semibold">
                {activeInvitationsCount}
                </span> {activeInvitationsCount > 1 ? 'activities' : 'activity'} created by others
              </>
            )}
            subtitle="Take a look at the projects, events, and offers you're involved in."
            linkText="View"
            to="/my-participating"
          />
        )}
      </div>
    </Section>
  );
};
