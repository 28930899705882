import getQueryUtils from './queryUtils';
import { REQUIRED_PARTNER_FIELDS } from '../../utils/user/REQUIRED_FIELDS';
import { useConnect } from '@lore/query-connect';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 16,
    timestamp,
    distance = {}
  } = props;

  const queryUtils = getQueryUtils(search, [
    'searchPartner',
    'name',
    'organization',
    'ages',
    'activities',
    'expertiseTypes',
    'experiences',
    'guidances',
    'groups',
    'locationCondition'
  ], timestamp);

  return useConnect('user.find', {
    where: {
      // distance: _.pick(distance, ['latitude', 'longitude']),
      eager: {
        $where: {
          $and: [...queryUtils.where],
          // isEducator: false,
          'tags.name': 'Partner',
          ...REQUIRED_PARTNER_FIELDS
        },
        // organizations: {}
      }
    },
    pagination: {
      page: search.page || '1',
      pageSize: pageSize,
      order: search.order || 'createdAt desc'
    }
  }, { keepPreviousData : true });
}
