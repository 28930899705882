import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '../../../components/Card';

Intent.propTypes = {
  title: PropTypes.node.isRequired,
  avatar: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Intent(props) {
  const {
    title,
    avatar,
    description,
    guidance,
    selected,
    onClick
  } = props;

  return (
    <Card
      className={classNames(
        'p-6 cursor-pointer hover:bg-cs-orange-50',
        selected ? 'border-cs-orange-500 border-2 bg-cs-orange-50' : 'border border-cs-gray-300 m-px'
      )}
      onClick={onClick}
    >
      <div className="text-xl text-cs-gray-800 font-semibold mb-1">
        {title}
      </div>
      <div className="text-base text-cs-gray-800 mb-4">
        {description}
      </div>
      {/*<img src={avatar} className="w-36 rounded-full bg-cs-orange-50 inline-block mb-6"/>*/}
      <div className="text-base text-cs-gray-800 italic">
        {guidance}
      </div>
    </Card>
  );
};
