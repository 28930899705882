import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PencilIcon } from '@heroicons/react/24/outline';
import TextAlert from './TextAlert';
import CheckboxCard from './CheckboxCard';
import { useUser } from '@lore/auth';
import useIntents from '../../../hooks/useIntents';

TagCheckboxCards.propTypes = {
  tags: PropTypes.object.isRequired,
  facadeTagNodes: PropTypes.object.isRequired
};

export default function TagCheckboxCards(props) {
  const {
    tags,
    facadeTagNodes,
    onChange,
    onUpdateDescription,
    selectable,
    describable = true
  } = props;

  return (
    <div className="grid grid-cols-1 gap-4">
      {tags.data.map(function renderExperiences(tag) {
        const facadeTagNode = _.find(facadeTagNodes.data, function(facadeTagNode) {
          return facadeTagNode._tag.id === tag.id;
        });

        const selected = !!facadeTagNode;

        return (
          <CheckboxCard
            key={tag.id}
            label={tag.data.name}
            description={facadeTagNode?._tagNode.data.description && (
              <div className="space-y-2">
                <div>
                  {facadeTagNode?._tagNode.data.description}
                </div>
                <div
                  className="text-sm text-cs-blue-500 cursor-pointer hover:opacity-60"
                  onClick={() => onUpdateDescription(tag, facadeTagNode)}
                >
                  Edit description
                </div>
              </div>
            )}
            checked={selected}
            onChange={function() {
              onChange(tag, facadeTagNode);
            }}
            selectable={selectable}
          >
            {describable && selected && !facadeTagNode?._tagNode.data.description && (
              <TextAlert
                icon={PencilIcon}
                onClick={() => onUpdateDescription(tag, facadeTagNode)}
                children={(
                  <>
                    Help others understand your experience by <span className="underline">providing more context</span>.
                  </>
                )}
              />
            )}
          </CheckboxCard>
        );
      })}
    </div>
  );
}
