import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import VirtualAvatar from '../../../components/User/VirtualAvatar';
import classNames from 'classnames';
import isSleeping from '../../../utils/isSleeping';
import { ExclamationTriangleIcon, MoonIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import useIntents from '../../../hooks/useIntents';

Wrapper.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Wrapper(props) {
  const {
    user,
    children
  } = props;

  const isAsleep = isSleeping(user);
  const currentUser = useUser();
  const { isEducator } = useIntents(user);

  if (user.data.inactive) {
    return (
      <div className={classNames(
        'flex flex-col sm:flex-row items-center rounded-xl shadow-lg p-8 space-x-0 space-y-6 sm:space-x-10 sm:space-y-0',
        isEducator ? 'bg-cs-purple-500' : 'bg-cs-blue-500'
      )}>
        <div>
          <VirtualAvatar size={36} user={user} />
        </div>
        <div className="flex-1 flex flex-col">
          <>
            <div className="flex items-center space-x-2 text-3xl text-white font-semibold mb-2">
              <ExclamationTriangleIcon className="w-8 h-8 mt-1"/>
              <span>
                This profile is inactive.
              </span>
            </div>
            <div className="text-white mb-4 max-w-xl">
              {user.data.firstName} is no longer an active member of CommunityShare.
            </div>
          </>
          {/*{children}*/}
        </div>
      </div>
    );
  }

  if (isAsleep) {
    return (
      <div className={classNames(
        'flex flex-col sm:flex-row items-center rounded-xl shadow-lg p-8 space-x-0 space-y-6 sm:space-x-10 sm:space-y-0',
        isEducator ? 'bg-cs-purple-500' : 'bg-cs-blue-500'
      )}>
        <div>
          <VirtualAvatar size={36} user={user} />
        </div>
        <div className="flex-1 flex flex-col">
          <>
            <div className="flex items-center space-x-2 text-3xl text-white font-semibold mb-2">
              <MoonIcon className="w-8 h-8"/>
              <span>
                This profile is asleep.
              </span>
            </div>
            <div className="text-white mb-4 max-w-xl">
              {user.data.firstName} will return to CommunityShare
              on <span className="font-semibold">{DateTime.fromISO(user.data.sleepingEndingAt).setZone(currentUser.data.timezone).toLocaleString(DateTime.DATE_MED)}</span>. Please check back then if you
              want to connect.
            </div>
          </>
          {/*{children}*/}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(
      'flex flex-col sm:flex-row items-center rounded-xl shadow-lg p-8 space-x-0 space-y-6 sm:space-x-10 sm:space-y-0',
      isEducator ? 'bg-cs-purple-500' : 'bg-cs-blue-500'
    )}>
      <div>
        <VirtualAvatar size={36} user={user} />
      </div>
      <div className="flex-1 flex flex-col">
        {isEducator ? (
          <>
            <div className="text-3xl text-white font-semibold mb-2">
              Ready to connect with {user.data.firstName}?
            </div>
            <div className="text-white mb-4 max-w-xl">
              You can message them about your offer, ask and answer questions, or get feedback.
            </div>
          </>
        ) : (
          <>
            <div className="text-3xl text-white font-semibold mb-2">
              Ready to connect with {user.data.firstName}?
            </div>
            <div className="text-white mb-4 max-w-xl">
              You can ask a quick question, get feedback on a project idea, see if they're
              interested in working with you, or ask them to refer you to someone else.
            </div>
          </>
        )}
        {children}
      </div>
    </div>
  );
}
