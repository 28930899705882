import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { BuildingOfficeIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { getDistanceFromLatLonInMiles } from '../../../components/User/utils';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import TextIconGroup from '../../../components_profiles/TextIconGroup';
import Questions from './Questions';
import Avatar from '../../../components/Avatar';

Collaborators.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Collaborators(props) {
  const { project } = props;

  // const creator = useConnect('user.byId', {
  //   id: project.data.creatorId
  // });
  //
  // if (
  //   creator.state === PayloadStates.FETCHING
  // ) {
  //   return (
  //     <div className="space-y-2">
  //       <div className="text-sm text-cs-gray-500">
  //         Collaborators
  //       </div>
  //       <Loader className="mx-7"/>
  //     </div>
  //   );
  // }

  return (
    <div className="space-y-2">
      <div className="text-sm text-cs-gray-500">
        Collaborators
      </div>
      <div className="flex space-x-2">

        <Questions project={project} />
      </div>
    </div>
  );
}
