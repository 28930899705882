import checkProfile from '../utils/checkProfile';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import useUserTags from './useUserTags';
import TagTypes from '../constants/TagTypes';

export default function useEducatorProfileMissingFields(_user) {
  const currentUser = useUser();

  const user = _user || currentUser;

  const userExpertises = useUserTags(user, TagTypes.Expertise);

  if (userExpertises.state === PayloadStates.FETCHING) {
    return null;
  }

  const { profile, interests } = checkProfile(user, {
    userExpertises,
    isEducator: true,
    isPartner: false
  });

  return {
    profile,
    interests,
    relations: {
      userExpertises
    }
  };
};
