import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Form from './Form';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useUserTags from '../../../hooks/useUserTags';
import useRouter from '../../../hooks/useRouter';
import useIntents from '../../../hooks/useIntents';
import useSetupNavigation from '../useSetupNavigation';

export default function Layout(props) {
  const { history } = useRouter();
  const user = useUser();

  const navigation = useSetupNavigation(Urls.EXPERIENCE);

  const { isEducator, isPartner } = useIntents();

  const experiences = useTags(TagTypes.Experience);
  const userExperiences = useUserTags(user, TagTypes.Experience);

  if (
    experiences.state === PayloadStates.FETCHING ||
    userExperiences.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout>
        <Helmet>
          <title>Setup - Experience</title>
        </Helmet>
        <Loader />
      </ParentLayout>
    );
  }

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Experience</title>
      </Helmet>
      <Form
        user={user}
        experiences={experiences}
        userExperiences={userExperiences}
        navigation={navigation}
        onPrevious={(newData) =>
          isPartner && isEducator ? history.push(Urls.SKILLS) :
          isPartner ? history.push(Urls.ACTIVITY_TYPES) :
          history.push(Urls.PAST_ENGAGEMENT)
        }
        onNext={(newData) => history.push(Urls.HOPE)}
        onSkip={() => history.push(Urls.HOPE)}
      />
    </ParentLayout>
  );
};
