import React from 'react';
import { useConnect } from '@lore/query-connect';
import useDialogConfig from '../_hooks/useDialogConfig';
import RedirectConnectDialog from '../../dialogs/redirect.connect';
import usePageRouter from '../_hooks/usePageRouter';
import useIntents from '../../hooks/useIntents';

export default function Launcher(props) {
  const config = useDialogConfig();
  const { isEducator } = useIntents();
  const { history } = usePageRouter();

  const user = useConnect('user.byId', {
    id: config.params.userId
  });

  return (
    <RedirectConnectDialog
      user={user}
      onCancel={props.onClose}
      onSubmit={function() {
        isEducator ?
          history.push('/post-project') :
          history.push('/post-offer')
      }}
    />
  );
};
